<template>
  <div id="test">
    <div id="threePage" style=" position:relative float:right"></div>
    <div id="label"></div>
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
let threeinit = {
  camera: null,
  scene: null,
  label: null,
  intersects: null,
  selectObject: null,
  canvas: null,
  renderer: null,
  width: null,
  height: null,
  controls: null,
  mouse: null,
  raycaster: null,
  particleMaterial: null,
  objects: [],
};
export default {
  name: "test",
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.initThree();
  },
  methods: {
    initThree() {
      this.createScene(); // 创建场景
      this.createLight(); // 创建光源
      this.createCamera(); // 创建相机
      this.createRender(); // 创建渲染器
      this.createModels(); // 创建模型
      this.createControls(); // 创建控件对象

      //创建控件对象
      threeinit.controls.addEventListener("change", animate); //监听鼠标、键盘事件
      function animate() {
        threeinit.renderer.render(threeinit.scene, threeinit.camera);
      }
      //  requestAnimationFrame(animate)
      animate();
    },
    // 创建场景
    createScene() {
      threeinit.scene = new THREE.Scene();
      threeinit.scene.background = new THREE.Color("#eee");
    },
    // 创建光源
    createLight() {
      //点光源
      var spotLight = new THREE.SpotLight(0xffffff, 1);
      spotLight.position.set(20, 50, 20);
      threeinit.scene.add(spotLight);
      const ambient2 = new THREE.AmbientLight(0xffffff, 0.4);
      threeinit.scene.add(ambient2);
    },
    // 创建相机
    createCamera() {
      threeinit.canvas = document.getElementById("threePage");
      threeinit.width = threeinit.canvas.offsetWidth; //窗口宽度
      threeinit.height = threeinit.canvas.offsetHeight; //窗口高度
      console.log(threeinit.width, threeinit.height, "heightheight");
      // var width = window.innerWidth; //窗口宽度
      // var height = window.innerHeight; //窗口高度
      var k = threeinit.width / threeinit.height; //窗口宽高比
      var s = 200; //三维场景显示范围控制系数，系数越大，显示的范围越大
      //创建相机对象
      threeinit.camera = new THREE.OrthographicCamera(
        -s * k,
        s * k,
        s,
        -s,
        1,
        1000
      );
      threeinit.camera.position.set(200, 300, 200); //设置相机位置
      threeinit.camera.lookAt(threeinit.scene.position); //设置相机方向(指向的场景对象)
    },
    // 创建渲染器
    createRender() {
      threeinit.renderer = new THREE.WebGLRenderer();
      threeinit.renderer.setSize(threeinit.width, threeinit.height); //设置渲染区域尺寸
      threeinit.renderer.setClearColor(0xb9d3ff, 1); //设置背景颜色

      threeinit.canvas.appendChild(threeinit.renderer.domElement); //id元素中插入canvas对象
    },
    // 创建模型
    createModels() {
      var axesHelper = new THREE.AxesHelper(250);
      threeinit.scene.add(axesHelper);
      const geometry1 = new THREE.BoxGeometry(10, 10, 10);
      const material1 = new THREE.MeshLambertMaterial({
        color: "red",
      }); //材质对象Material
      const mesh1 = new THREE.Mesh(geometry1, material1); //网格模型对象Mesh
      mesh1.position.set(0, 10, 10); //设置相机位置
      mesh1.meshName = `mesh1`;
      threeinit.scene.add(mesh1); //网格模型添加到场景中

      const mesh2 = new THREE.Mesh(geometry1, material1); //网格模型对象Mesh
      mesh2.position.set(0, 10, 30); //设置相机位置
      mesh2.meshName = `mesh2`;
      threeinit.scene.add(mesh2); //网格模型添加到场景中

      const mesh3 = new THREE.Mesh(geometry1, material1); //网格模型对象Mesh
      mesh3.position.set(0, 10, 50); //设置相机位置
      mesh3.meshName = `mesh3`;
      threeinit.scene.add(mesh3); //网格模型添加到场景中
    },
    // 创建控件对象
    createControls() {
      threeinit.controls = new OrbitControls(
        threeinit.camera,
        threeinit.renderer.domElement
      );
      threeinit.canvas.addEventListener(
        "mousedown",
        this.onDocumentMouseDown,
        false
      );
      threeinit.canvas.addEventListener(
        "touchstart",
        this.onDocumentTouchStart,
        false
      );
    },
    onDocumentTouchStart(event) {
      event.preventDefault();
      event.clientX = event.touches[0].clientX;
      event.clientY = event.touches[0].clientY;
      this.onDocumentMouseDown(event);
    },
    // 鼠标双击触发的方法
    onDocumentMouseDown(event) {
      event.preventDefault();
      // 获取 raycaster 和所有模型相交的数组，其中的元素按照距离排序，越近的越靠前
        threeinit.intersects = this.getIntersects(event);
        console.log(threeinit.intersects, "intersects");
        // 获取选中最近的 Mesh 对象
    //     if (
    //       threeinit.intersects.length != 0 &&
    //       threeinit.intersects[0].object instanceof THREE.Mesh
    //     ) {
    //       console.log(threeinit.intersects, "intersects11111");
    //       if (threeinit.selectObject != threeinit.intersects[0].object) {
    //         // 		if (threeinit.selectObject) {
    //         // 			threeinit.selectObject.material.color.set(0xF9AB0B);
    //         // 			threeinit.selectObject.material.transparent=true;//透明度的变化
    //         // 			threeinit.selectObject.material.opacity=0.9;
    //         // 		}
    //         threeinit.selectObject = threeinit.intersects[0].object;
    //         console.log(threeinit.selectObject, "selectObject");
    //         // 		//获取杆件信息
    //         // 		// this.getMemberData(threeinit.selectObject.meshName);
    //         this.rendMembers();
    //         // 		//改变物体的颜色(红色)
    //         // 		// this.$nextTick(() => {
    //         this.renderDiv(threeinit.selectObject);
    //         // 		// })
    //       }
    //       // else {
    //       // 	threeinit.selectObject.material.color.set(0xF9AB0B)
    //       // 	threeinit.label.style.visibility = 'hidden';
    //       // 	threeinit.selectObject = null;
    //       // 	// console.log("未选中 Mesh!");
    //       // }

    // }


 // 数组大于0 表示有相交对象
        // if ( threeinit.intersects.length > 0) {
        //   if (lastPick) {
        //     lastPick.object.material.color.set('yellow')
        //   }
        //   lastPick =  threeinit.intersects[0]
        // } else {
        //   if (lastPick) {
        //     // 复原
        //     lastPick.object.material.color.set(0x6688aa)
        //     lastPick = null
        //   }
        // }
      }





	,
    getIntersects(event) {
      event.preventDefault();
      threeinit.label = document.getElementById("label");
      // 声明 raycaster 和 mouse 变量
      threeinit.mouse = new THREE.Vector2();
      let getBoundingClientRect = threeinit.canvas.getBoundingClientRect();
      threeinit.mouse.x =
        ((event.clientX - getBoundingClientRect.left) /
          threeinit.canvas.offsetWidth) *
          2 -
        1;
      threeinit.mouse.y =
        -(
          (event.clientY - getBoundingClientRect.top) /
          threeinit.canvas.offsetHeight
        ) *
          2 +
        1;
      console.log(threeinit.mouse.x, threeinit.mouse.y, "mouse.ymouse.y");
      let standardVector = new THREE.Vector3(
        threeinit.mouse.x,
        threeinit.mouse.y,
        1
      ); // 标准设备坐标
      console.log(standardVector, "standardVector");
      // 标准设备坐标转世界坐标
      let worldVector = standardVector.unproject(threeinit.camera);
      // 射线投射方向单位向量(worldVector坐标减相机位置坐标)
      let ray = worldVector.sub(threeinit.camera.position).normalize();
      // 创建射线投射器对象
      threeinit.raycaster = new THREE.Raycaster(threeinit.camera.position, ray);
      // 通过鼠标点击的位置(二维坐标)和当前相机的矩阵计算出射线位置
      // 获取与射线相交的对象数组，其中的元素按照距离排序，越近的越靠前
      threeinit.intersects = threeinit.raycaster.intersectObjects(
        threeinit.scene.children,
        true
      );
      // console.log(threeinit.intersects,'returnintersects')
      // label.style.visibility = 'hidden'
      // 返回选中的对象
      return threeinit.intersects;
    },
    // 改变对象材质属性
    changeMaterial(object) {
      const material = new THREE.MeshLambertMaterial({
        color: 0x00ff00,
        transparent: object.material.transparent ? false : true,
        opacity: 0.8,
      });
      object.material = material;
    },
    rendMembers() {
      if (
        threeinit.selectObject != undefined &&
        threeinit.selectObject != null
      ) {
        this.renderDiv(threeinit.selectObject);
      }
      threeinit.renderer.render(threeinit.scene, threeinit.camera);
      //   requestAnimationFrame(this.rendMembers);
    },
    // 更新div的位置
    renderDiv(object) {
      this.changeMaterial(object);
      //  获取窗口的一半高度和宽度
      const halfWidth = threeinit.canvas.clientWidth / 2;
      const halfHeight = threeinit.canvas.clientWidth / 4;
      //  逆转相机求出二维坐标
      var vector = object.position.clone().project(threeinit.camera);
      threeinit.label.style.left = vector.x * halfWidth - halfWidth / 2 + "px";
      threeinit.label.style.top =
        -vector.y * halfHeight + halfHeight - object.position.y + "px";

      // const name = object.parent.name
      // if(object.meshName===undefined){
      // 	threeinit.label.textContent=`x: ${object.position.x} ,y: ${object.position.y},z:${object.position.z}`;
      // }else{
      threeinit.label.textContent = `
					Name：${object.meshName}
					`;
      console.log(threeinit.label.textContent, "threeinit.label.textContent");
      // }
    },
  },
  watch: {},
  beforeDestroy() {},
};
</script>

<style lang="scss">
#threePage {
  width: 400px;
  height: 400px;
  //   position: fixed;
  //   left: 0;
  //   top: 0;
}
#test {
  width: 400px;
  height: 400px;
  //   position: fixed;
  //   left: 0;
  //   top: 0;
}
#label {
  width: 100px;
  /* height: 400px; */
  position: absolute;
  z-index: 2000;
  padding: 10px;
  background: rgba(247, 83, 33, 0.856);
  padding: 10px;
  border-radius: 2px;
  visibility: hidden;
  font-size: 20px;
}
</style>
